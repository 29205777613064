import { AxiosResponse } from 'axios'
import { map, pipe, reject, snakeCase } from 'lodash/fp'

import {
  AllTicketTimeAttributeNames,
  AllTicketTimeAttributes,
} from 'packages/grimoire/src/ticket-time/ticket-time.types'
import { OfflinePatchData } from 'packages/offline'
import { flippedIncludes } from 'packages/utils/misc/array.helpers'
import {
  RequestConfig,
  ToOneRelationship,
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
} from 'packages/utils/store'

import { Ticket } from '../tickets'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum TicketTimesActionTypes {
  CREATE_FINALIZED_TICKET_TIME = 'TICKET_TIMES/API/CREATE_FINALIZED_TICKET_TIME',
  CREATE_FINALIZED_TICKET_TIME_FAILURE = 'TICKET_TIMES/CREATE_FINALIZED_TICKET_TIME_FAILURE',
  CREATE_FINALIZED_TICKET_TIME_SUCCESS = 'TICKET_TIMES/CREATE_FINALIZED_TICKET_TIME_SUCCESS',

  CREATE_TICKET_TIME = 'TICKET_TIMES/API/CREATE_TICKET_TIME',
  CREATE_TICKET_TIME_FAILURE = 'TICKET_TIMES/CREATE_TICKET_TIME_FAILURE',
  CREATE_TICKET_TIME_SUCCESS = 'TICKET_TIMES/CREATE_TICKET_TIME_SUCCESS',

  DELETE_TICKET_TIME = 'TICKET_TIMES/API/DELETE_TICKET_TIME',

  DELETE_TICKET_TIME_FAILURE = 'TICKET_TIMES/DELETE_TICKET_TIME_FAILURE',
  DELETE_TICKET_TIME_SUCCESS = 'TICKET_TIMES/DELETE_TICKET_TIME_SUCCESS',
  FETCH_TICKET_TIMES = 'TICKET_TIMES/API/FETCH_TICKET_TIMES',

  FETCH_TICKET_TIMES_FAILURE = 'TICKET_TIMES/FETCH_TICKET_TIMES_FAILURE',
  FETCH_TICKET_TIMES_SUCCESS = 'TICKET_TIMES/FETCH_TICKET_TIMES_SUCCESS',
  FETCH_TICKET_TIME_BY_ID = 'TICKET_TIMES/API/FETCH_TICKET_TIME_BY_ID',

  FETCH_TICKET_TIME_BY_ID_FAILURE = 'TICKET_TIMES/FETCH_TICKET_TIME_BY_ID_FAILURE',
  FETCH_TICKET_TIME_BY_ID_SUCCESS = 'TICKET_TIMES/FETCH_TICKET_TIME_BY_ID_SUCCESS',
  OFFLINE_CREATE_TICKET_TIME = 'TICKET_TIMES/OFFLINE/CREATE_TICKET_TIME',

  OFFLINE_CREATE_TICKET_TIME_FAILURE = 'TICKET_TIMES/OFFLINE/CREATE_TICKET_TIME_FAILURE',
  OFFLINE_CREATE_TICKET_TIME_SUCCESS = 'TICKET_TIMES/OFFLINE/CREATE_TICKET_TIME_SUCCESS',
  OFFLINE_UPDATE_TICKET_TIME = 'TICKET_TIMES/OFFLINE/UPDATE_TICKET_TIME',

  OFFLINE_UPDATE_TICKET_TIME_FAILURE = 'TICKET_TIMES/OFFLINE/UPDATE_TICKET_TIME_FAILURE',
  OFFLINE_UPDATE_TICKET_TIME_SUCCESS = 'TICKET_TIMES/OFFLINE/UPDATE_TICKET_TIME_SUCCESS',
  RELOAD_OFFLINE_TICKET_TIMES = 'TICKET_TIMES/RELOAD_OFFLINE_TICKET_TIMES',

  RELOAD_OFFLINE_TICKET_TIMES_FAILURE = 'TICKET_TIMES/RELOAD_OFFLINE_TICKET_TIMES_FAILURE',
  RELOAD_OFFLINE_TICKET_TIMES_SUCCESS = 'TICKET_TIMES/RELOAD_OFFLINE_TICKET_TIMES_SUCCESS',
  REMOVE_LOCAL_TICKET_TIME = 'TICKET_TIMES/REMOVE_LOCAL_TICKET_TIME',

  SILENT_DELETE_TICKET_TIME = 'TICKET_TIMES/API/SILENT_DELETE_TICKET_TIME',
  SILENT_DELETE_TICKET_TIME_FAILURE = 'TICKET_TIMES/SILENT_DELETE_TICKET_TIME_FAILURE',
  SILENT_DELETE_TICKET_TIME_SUCCESS = 'TICKET_TIMES/SILENT_DELETE_TICKET_TIME_SUCCESS',

  UPDATE_TICKET_TIME = 'TICKET_TIMES/API/UPDATE_TICKET_TIME',
  UPDATE_TICKET_TIME_FAILURE = 'TICKET_TIMES/UPDATE_TICKET_TIME_FAILURE',
  UPDATE_TICKET_TIME_SUCCESS = 'TICKET_TIMES/UPDATE_TICKET_TIME_SUCCESS',
}

export type TicketTimesState = {
  data: JSONApiTicketTimesMap
  offlineData: Record<string, OfflineTicketTime>
  requestPending: boolean
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type TicketTimesResponse = {
  ticketTime: JSONApiTicketTimesMap
}

export type TicketTimePostData = {
  isExternalComment?: boolean
  isFinalized?: boolean
  notes?: string
  startedAt: string
  stoppedAt?: string
  ticketId: string
  userId: string
}

export type TicketTimePostApiAttributes = {
  is_finalized?: boolean
  notes?: string
  started_at: string
  stopped_at?: string
  ticket_id: string
}

export type TicketTimePatchData = {
  id: string
} & TicketTimePatchAttributes

type StaticTicketTimePatchData = {
  isExternalComment?: boolean
  isFinalized?: boolean
  notes?: string
  startedOffline?: boolean
}

export type TicketTimePatchAttributes = {
  startedAt?: string | null
  stoppedAt?: string | null
} & StaticTicketTimePatchData

export type TicketTimeOfflinePatchData = {
  startedAt?: string | null
  stoppedAt?: string | null
} & StaticTicketTimePatchData

export type TicketTimePatchApiAttributes = {
  is_finalized?: boolean
  notes?: string
  started_at?: string | null
  stopped_at?: string | null
}

export type NormalizedTicketTimesApiResponse =
  NormalizedJSONApiResponse<TicketTimesResponse>

export type TicketTimeRequestConfig =
  RequestConfig<NormalizedTicketTimesApiResponse>

export type TicketTimesServiceResponse =
  Promise<NormalizedTicketTimesApiResponse>

export type TicketTimesApiResponse =
  AxiosResponse<NormalizedTicketTimesApiResponse>

/**********************************************************
 * TICKET TIME
 *********************************************************/
export type TicketTime = {
  id: string
  ticket: Ticket
} & TicketTimeAttributes

export type TicketTimeRelationships = {
  ticket: ToOneRelationship
  user: ToOneRelationship
}

export type TicketTimeAttributes = AllTicketTimeAttributes

export const TicketTimeAttributeNames = AllTicketTimeAttributeNames

/**
Attributes that we don't or can't use in requests that we remove below:
- API does not accept "notes" as a field request, but we do still need it
for offline timers storage
- StartedOffline is an attribute that only exists locally
*/
const localAttributes = ['notes', 'startedOffline', 'isExternalComment']

export const TicketTimeApiFields = pipe(
  reject(flippedIncludes<string>(localAttributes)),
  map(snakeCase),
)(TicketTimeAttributeNames)

export type OfflineTicketTime = OfflinePatchData<TicketTimeOfflinePatchData>
export type RawTicketTime = JSONApiObjectWithRelationships<
  TicketTimeAttributes,
  TicketTimeRelationships
>

export type JSONApiTicketTimesMap = JSONApiObjectWithRelationshipsMap<
  TicketTimeAttributes,
  TicketTimeRelationships
>
