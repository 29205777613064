import { useDecision } from '@optimizely/react-sdk'
import classNames from 'classnames'
import React from 'react'

import { Button, Card, Tooltip, TooltipPosition } from 'packages/common'
import { UnitRealtimeStatus } from 'packages/grimoire'
import { TicketReasonForAssignment } from 'packages/grimoire/src/ticket'
import { IconName, SvgIcon } from 'packages/iconic'
import { Features } from 'packages/optimizely'
import { DateFormat, format } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Ticket } from 'app/fieldapp/store/tickets'
import { Unit } from 'app/fieldapp/store/units'

import { OwnerCommentBadge } from '../../core/components/OwnerCommentBadge'
import { useTranslateTickets } from '../hooks'
import { getTicketColorClassBySeverity } from '../tickets.helpers'

import styles from './TicketCard.module.scss'


const iconTextStyles: React.CSSProperties = {
  fontWeight: 'bold',
  lineHeight: '16px',
  textTransform: 'uppercase',
}

export const TicketCardTestIds = {
  coverageIcon: 'TicketCard__coverageIcon',
}

export type TicketCardProps = {
  canAddToExistingVisit: boolean
  handleOpenVisitDrawer: (visitUnitId: string) => void
  isLoading: boolean
  isOverdue: boolean
  onAddTicketClick: () => void
  onClick: () => void
  ticket: Ticket
  unit: Unit
}

export const TicketCard: React.FC<TicketCardProps> = ({
  canAddToExistingVisit = true,
  isLoading,
  isOverdue,
  onAddTicketClick,
  onClick,
  handleOpenVisitDrawer,
  ticket,
  unit,
}) => {
  const { dueDateNoTimestamp, severity, title, ownerCommentStatus } = ticket

  const isUnitOccupied =
    unit.realtimeStatus === UnitRealtimeStatus.OCCUPIED_GUEST ||
    unit.realtimeStatus === UnitRealtimeStatus.OWNER_HOLD

  const { ut } = useI18n()
  const { translateSeverity } = useTranslateTickets()
  const addressStr = `${unit.address}, ${unit.city}, ${unit.state}`
  const dueDateStr = dueDateNoTimestamp
    ? format(dueDateNoTimestamp, DateFormat.MonthDateAndYear)
    : ut(Slugs.unknown)
  const severityClass = getTicketColorClassBySeverity(severity)

  const handleOnAddTicketClick = React.useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      e.stopPropagation()
      onAddTicketClick()
    },
    [onAddTicketClick],
  )

  const isAssignmentForCoverage = React.useMemo(
    () =>
      ticket.reasonForAssignment
        ? [
            TicketReasonForAssignment.COVERAGE_PARTNER,
            TicketReasonForAssignment.CROSS_COVERAGE,
          ].includes(ticket.reasonForAssignment)
        : false,
    [ticket.reasonForAssignment],
  )

  const isOnline = useOnlineStatus().isOnline()

  const onAddToNewVisitClick = (e: React.MouseEvent, visitUnitId: string) => {
    e.stopPropagation()

    handleOpenVisitDrawer(visitUnitId)
  }

  const [decision] = useDecision(Features.IS_TICKET_PROCESSED_FLAG)
  const isFeatureEnabled = React.useMemo(
    () => decision.enabled,
    [decision.enabled],
  )

  return (
    <Card
      className={styles.ticketCard}
      dataTestId={`ticket-card-${ticket.id}`}
      onClick={onClick}
    >
      <div className={classNames(styles.statusBar, styles[severityClass])}>
        <SvgIcon
          centerItems={true}
          icon={IconName.tag}
          text={translateSeverity(severity)}
          textFontSize={12}
          textStyleOverrides={iconTextStyles}
        />

        {isOverdue && (
          <Tooltip
            message={ut(Slugs.overdueTooltipText)}
            position={TooltipPosition.Top}
          >
            <SvgIcon
              centerItems={true}
              className={styles.overdueIcon}
              icon={IconName.alertTriangle}
              size={14}
            />
          </Tooltip>
        )}

        <span>
          <b>{ut(Slugs.due)}: </b>
          {dueDateStr}
        </span>
      </div>

      <div className={styles.infoContainer}>
        {!(ownerCommentStatus === 'none') && (
          <div className={styles.ownerCommentBadgeContainer}>
            <OwnerCommentBadge hasBellAlert={ownerCommentStatus === 'newest'} />
          </div>
        )}
        <div className={styles.title}>{title}</div>
        <div className={styles.codeContainer}>
          <div className={styles.code}>{unit.unitCode}</div>
          {isUnitOccupied && <SvgIcon icon={IconName.doorClose} size={20} />}
          {isAssignmentForCoverage && (
            <SvgIcon
              className={styles.coverageIcon}
              dataTestId={TicketCardTestIds.coverageIcon}
              icon={IconName.userCircleSwitch}
              size={20}
            />
          )}
        </div>
        <div className={styles.address}>{addressStr}</div>
      </div>
      {canAddToExistingVisit && (!isFeatureEnabled || !ticket.isTicketProcessed) && (
        <Button
          buttonType="text"
          className={styles.addTicketButton}
          disabled={!isOnline}
          isLoading={isLoading}
          onClick={handleOnAddTicketClick}
        >
          {ut(Slugs.addToMyVisit)}
        </Button>
      )}
      {ticket?.openVisitId && (
        <div className={styles.alreadyAddedToVisit}>
          {ut(Slugs.alreadyAddedToVisit)}
        </div>
      )}
      {isFeatureEnabled && ticket.isTicketProcessed && (
        <div className={styles.alreadyAddedToVisit}>
          {ut(Slugs.alreadyProcessed)}
        </div>
      )}
      {!ticket?.openVisitId && !canAddToExistingVisit && (!isFeatureEnabled || !ticket.isTicketProcessed) && (
        <Button
          buttonType="text"
          className={styles.addTicketButton}
          disabled={!isOnline}
          onClick={e => onAddToNewVisitClick(e, ticket?.unitId)}
        >
          {ut(Slugs.addToNewVisit)}
        </Button>
      )}
    </Card>
  )
}
