import styled from '@emotion/styled'
import { useDecision } from '@optimizely/react-sdk'
import React from 'react'

import { Alert, ExternalLink } from 'packages/common'
import { SanitizedHTML } from 'packages/common/src/SanitizedHTML'
import { Features } from 'packages/optimizely'
import { centerWithFlex, colors, text } from 'packages/styles'
import { DateFormat, formatLocalized } from 'packages/utils/dateHelpers'
import { AsyncState } from 'packages/utils/hooks'
import { useWrappedEvent } from 'packages/utils/hooks/useWrappedEvent'
import { getAdminViewTicketURL } from 'packages/utils/links'

import { MapsLink } from 'app/fieldapp/components/core'
import { useTranslateTickets } from 'app/fieldapp/components/tickets/hooks'
import { UseTicketDetails } from 'app/fieldapp/components/tickets/TicketDetail/useTicketDetails'
import { StartTimerButton } from 'app/fieldapp/components/timers/components/Buttons/StartTimerButton'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Ticket } from 'app/fieldapp/store/tickets'
import { Unit } from 'app/fieldapp/store/units'
import { fullName, User } from 'app/fieldapp/store/users'
import { Visit } from 'app/fieldapp/store/visits'

import { TicketComments } from '../../../tickets/TicketComments'
import {
  TicketSeverityText,
  TicketStatusBar,
  VisitTicketSection,
  VisitTicketSectionTitle,
  YouBadge,
} from '../TaskSharedStyles/VisitTicket.styles'
import { VisitTicketCardTestIds } from '../VisitDetail/components/VisitTicketCard/VisitTicketCard'
import { getTicketVisitStatus } from './VisitTicketDrawer.helpers'
import { VisitTicketStatusInfo } from './VisitTicketStatusInfo'

const St = {
  Address: styled.div`
    ${text.bodyRegularSmall};
    margin-top: 4px;
  `,
  Assignee: styled.div`
    ${text.bodyRegularDefault};
    align-items: center;
    display: flex;
  `,
  DateBlock: styled.div`
    flex-grow: 1;
    min-width: 50%;
    padding: 12px 16px;
    word-break: break-word;

    &:not(:first-of-type) {
      border-left: 1px solid ${colors.midnight20};
      }
      `,
  DatesSection: styled.div`
      ${centerWithFlex};
      align-items: flex-start;
      flex-direction: row;
      `,
  Description: styled(SanitizedHTML)`
      ${text.bodyRegularDefault};
      `,
  MapLink: styled.div`
      margin-top: 16px;
      `,
  Phone: styled.a`
      ${text.bodyRegularDefault};
      color: ${colors.lake};
      font-weight: 600;
      `,
  ProcessedAlert: styled(Alert)`
      margin: 16px 0 0 0;
    `,
  RequestedBy: styled(VisitTicketSectionTitle)`
    margin-top: 24px;
  `,
  Section: styled(VisitTicketSection)`
    position: relative;
  `,
  Separator: styled.hr`
    border: 0;
    border-bottom: 1px solid ${colors.midnight20};
    margin: 0;
    padding: 0;
  `,
  SeverityText: TicketSeverityText,
  StartTimerButtonWrapper: styled.div`
    bottom: 24px;
    position: absolute;
    right: 16px;
  `,
  StatusBar: TicketStatusBar,
  SubTitle: VisitTicketSectionTitle,
  TemplateBody: styled(VisitTicketSectionTitle)`
    margin-top: 24px;
  `,
  Text: styled.div`
    ${text.bodyRegularDefault};
  `,
  Title: styled.div`
    ${text.headingDefault};
    margin-bottom: 16px;
  `,
  UnitName: styled.div`
    ${text.bodyBoldSmall};
  `,
  ViewTicketLink: styled(ExternalLink)`
    font-size: 16px;
    svg {
      height: 16px;
      width: 16px;
    }
  `,
  Visibility: styled(VisitTicketSectionTitle)`
    margin-top: 24px;
  `,
  VisitTicketDetails: styled.div`
    padding-bottom: 64px;
    padding-top: 48px;
  `,
  VisitTicketSection,
  You: styled(YouBadge)`
    padding-left: 6px;
  `,
}

const useTranslations = (ticket: Ticket) => {
  const { ut } = useI18n()
  const { translateSeverity } = useTranslateTickets()

  return {
    assignedTo: ut(Slugs.assignedTo),
    comments: ut(Slugs.comments),
    created: ut(Slugs.created),
    description: ut(Slugs.description),
    due: ut(Slugs.due),
    isTicketProcessed: ut(Slugs.isTicketProcessed),
    requestedBy: ut(Slugs.requestedBy),
    severity: translateSeverity(ticket.severity),
    templateDetails: ut(Slugs.templateDetails),
    unassigned: ut(Slugs.unassigned),
    unknown: ut(Slugs.unknown),
    viewTicket: ut(Slugs.viewTicket),
    visibility: ut(Slugs.visibility),
    visibilityValues: {
      internal: ut(Slugs.staffOnly),
      owner: ut(Slugs.staffAndOwner),
    },
    you: ut(Slugs.you),
  }
}

export type VisitTicketDetailsProps = {
  assignee?: User
  completeTicketRequestState: AsyncState<unknown>
  isAssignedToCurrentUser: boolean
  onCompleteTicketClick: () => void
  ticket: Ticket
  ticketCommentIds: string[]
  ticketTimerProps: UseTicketDetails
  unit: Unit
  user: User | undefined
  visit: Visit | undefined
}

export const VisitTicketDetails: React.FC<VisitTicketDetailsProps> = React.memo(
  props => {
    const {
      assignee,
      completeTicketRequestState,
      isAssignedToCurrentUser,
      onCompleteTicketClick,
      ticket,
      ticketCommentIds,
      ticketTimerProps,
      unit,
      user,
      visit,
    } = props
    const strings = useTranslations(ticket)
    const { stopEvent } = useWrappedEvent()
    const status = getTicketVisitStatus(props, user)

    const cityStateText = `${unit.city}, ${unit.state}`
    const ticketInAdminURL = getAdminViewTicketURL(ticket.id)
    const assigneeText = assignee ? fullName(assignee) : strings.unassigned

    const isAttachedToVisit = visit?.ticketIds?.includes(ticket.id)
    const canRunTimers = isAssignedToCurrentUser && isAttachedToVisit

    const createdText = formatLocalized(
      ticket.createdAt,
      DateFormat.MonthShortDateAndYear,
    )
    const dueText = ticket.dueDateNoTimestamp
      ? formatLocalized(
        ticket.dueDateNoTimestamp,
        DateFormat.MonthShortDateAndYear,
      )
      : strings.unknown

    const [decision] = useDecision(Features.IS_TICKET_PROCESSED_FLAG)
    const isFeatureEnabled = React.useMemo(
      () => decision.enabled,
      [decision.enabled],
    )

    return (
      <St.VisitTicketDetails>
        <St.StatusBar severity={ticket.severity}>
          <St.SeverityText>{strings.severity}</St.SeverityText>
        </St.StatusBar>

        {/* TITLE & ADDRESS INFO */}
        <St.Section>
          <St.Title>{ticket.title}</St.Title>
          <St.UnitName>{unit.name}</St.UnitName>
          <St.Address>
            <div>{unit.address}</div>
            <div>{cityStateText}</div>
          </St.Address>

          <St.MapLink>
            <MapsLink
              address={unit.address}
              city={unit.city}
              state={unit.state}
            />
          </St.MapLink>

          {canRunTimers && (!isFeatureEnabled || !ticket.isTicketProcessed) && (
            <St.StartTimerButtonWrapper>
              <StartTimerButton
                dataTestId={VisitTicketCardTestIds.startTimerBtn}
                disabled={ticketTimerProps.isTimerDisabled}
                isLoading={ticketTimerProps.isTimerLoading}
                onClick={stopEvent(ticketTimerProps.handleStartTimer)}
              />
            </St.StartTimerButtonWrapper>
          )}

          {ticket.isTicketProcessed && isFeatureEnabled && (
            <St.ProcessedAlert alertType='warning'>
              {strings.isTicketProcessed}
            </St.ProcessedAlert>
          )}
        </St.Section>
        <St.Separator />

        {/* DESCRIPTION & REQUESTED BY INFO */}
        <St.Section>
          <St.SubTitle>{strings.description}</St.SubTitle>
          <St.Description html={ticket.description || ''} />

          {!!ticket.templateBody && (
            <>
              <St.TemplateBody>{strings.templateDetails}</St.TemplateBody>
              <St.Text>{ticket.templateBody}</St.Text>
            </>
          )}

          <St.RequestedBy>{strings.requestedBy}</St.RequestedBy>
          <St.Text>{ticket.requestedBy}</St.Text>

          <St.Visibility>{strings.visibility}</St.Visibility>
          <St.Text>{strings.visibilityValues[ticket.visibility]}</St.Text>

          <div>
            <St.ViewTicketLink
              text={strings.viewTicket}
              url={ticketInAdminURL}
            />
          </div>
        </St.Section>
        <St.Separator />

        {/* DATES INFO */}
        <St.DatesSection>
          <St.DateBlock>
            <St.SubTitle>{strings.created}</St.SubTitle>
            <St.Text>{createdText}</St.Text>
          </St.DateBlock>
          <St.DateBlock>
            <St.SubTitle>{strings.due}</St.SubTitle>
            <St.Text>{dueText}</St.Text>
          </St.DateBlock>
        </St.DatesSection>
        <St.Separator />

        {/* ASSIGNMENT INFO */}
        <St.Section>
          <St.SubTitle>{strings.assignedTo}</St.SubTitle>
          <St.Assignee>
            {assigneeText}
            {isAssignedToCurrentUser && <St.You>{strings.you}</St.You>}
          </St.Assignee>

          {assignee?.mobilePhone ? (
            <St.Phone href={`tel:${assignee.mobilePhone}`}>
              {assignee.mobilePhone}
            </St.Phone>
          ) : null}

          <VisitTicketStatusInfo
            completeTicketRequestState={completeTicketRequestState}
            onCompleteTicketClick={onCompleteTicketClick}
            status={status}
            ticketStatus={ticket.status}
          />
        </St.Section>
        <St.Separator />

        {/* COMMENTS SECTION */}
        <St.VisitTicketSection>
          <TicketComments commentIds={ticketCommentIds} ticketId={ticket.id} />
        </St.VisitTicketSection>
      </St.VisitTicketDetails>
    )
  },
)
