export type GenericTimer = {
  id: string
} & GenericTimerAttributes

export type GenericTimerAttributes = {
  isFinalized: boolean
  startedAt: string
  startedOffline?: boolean
  stoppedAt: string | null
}

export enum TimerType {
  CLEAN = 'clean',
  OTHER = 'other',
  TICKET = 'ticket',
}

export type TimerErrorData = {
  // ISO datetime string
  error_reason: string
  id: string
  started_at: string // ISO datetime string
  stopped_at: string
  timer_type: TimerType
}

export type TimersData = {
  cnt_pending: number
  has_pending: boolean
  timer_errors: TimerErrorData[]
}
