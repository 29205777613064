import styled from '@emotion/styled'
import classNames from 'classnames'
import * as React from 'react'

import {
  DropdownButton,
  DropdownCaret,
  DropdownMenuProps,
} from 'packages/common'
import { daysFromFreezeWindow } from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'
import {
  DateFormat,
  formatLocalized,
  isBefore,
  isSameDay,
  startOfDay,
  startOfToday,
  addDays,
} from 'packages/utils/dateHelpers'

import {
  CreateVisitDrawer,
  LastFetchBanner,
} from 'app/fieldapp/components/core'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { BucketedCleans } from 'app/fieldapp/store/cleans'
import { Visit } from 'app/fieldapp/store/visits'

import { TimelineDay } from '../TimelineDay'
import { CleanTimelinePassThroughProps } from './CleanTimeline.container'

import styles from './CleansTimeline.module.scss'

const EMPTY_ARR = []

const StyledMenuItem = styled.span<{ active: boolean }>`
  color: ${colors.lake};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`

const CustomCaret = styled(DropdownCaret)`
  border-left: 6px solid ${colors.lake};
  margin-left: 16px;
`

const St = {
  ActiveVisitsContainer: styled.div`
    padding: 0px 16px;
    cursor: pointer;
    margin-bottom: 8px;
  `,

  ActiveVisitsHeader: styled.div`
    display: flex;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    margin-bottom: 8px;
  `,

  ActiveVisitsTitle: styled.span`
    color: ${colors.lake};
    font-weight: bold;
    margin-right: 8px;
  `,

  Calendar: styled(SvgIcon)`
    color: ${colors.lake};
  `,

  CollapseIcon: styled(SvgIcon)`
    color: ${colors.lake};
    transition: transform 0.2s;
    &.collapsed {
      transform: rotate(-90deg);
    }
  `,

  ViewPickerContainer: styled.div`
    align-items: center;
    display: flex;
    grid-gap: 4px;
  `,
}

const TODAY = startOfToday()

export enum CleansTimelineTestIds {
  activeVisitsContainer = 'CleansTimeline__activeVisitsContainer',
  activeVisitsHeader = 'CleansTimeline__activeVisitsHeader',
  container = 'CleansTimeline__container',
  select = 'CleansTimeline__select',
}

export type FieldAppScheduleView = 'all' | 'day'

export type CleansTimelineProps = {
  activeVisits: Visit[]
  cleanBuckets: BucketedCleans
  lastFetchTimestamp: number
  setView: (view: FieldAppScheduleView) => void
  showDatePickerModal: () => void
  view: FieldAppScheduleView
  visitsOnNextDay: Visit[]
  visitsOnToday: Visit[]
} & CleanTimelinePassThroughProps

export const CleansTimeline: React.FC<CleansTimelineProps> = React.memo(
  ({
    cleanBuckets,
    lastFetchTimestamp,
    onCleanClick,
    setView,
    showDatePickerModal,
    view,
    visitsOnToday,
    visitsOnNextDay,
    activeVisits,
  }) => {
    const { t } = useI18n()
    const [showActiveVisits, setShowActiveVisits] = React.useState(false)

    // Extract and deduplicate all day keys across all months
    const dedupedDayEntries = React.useMemo(() => {
      const dayMap = new Map()

      // Get all days from all months and keep only one entry per unique day
      Object.entries(cleanBuckets).forEach(([monthKey, monthBucket]) => {
        Object.entries(monthBucket).forEach(([dayKey, dayBucket]) => {
          // Only keep the first occurrence of each day
          if (!dayMap.has(dayKey)) {
            dayMap.set(dayKey, {
              dayBucket,
              dayKey,
              monthKey,
            })
          }
        })
      })

      // Convert to array and sort chronologically
      return Array.from(dayMap.values()).sort((a, b) =>
        a.dayKey.localeCompare(b.dayKey),
      )
    }, [cleanBuckets])

    const menuItems = React.useMemo(() => {
      return [
        {
          children: (
            <StyledMenuItem active={view === 'all'}>
              {t(Slugs.all)}
            </StyledMenuItem>
          ),
          key: 'all',
          onClick: e => setView(e.key),
        },
        {
          children: (
            <StyledMenuItem active={view === 'day'}>
              {t(Slugs.day)}
            </StyledMenuItem>
          ),
          key: 'day',
          onClick: e => setView(e.key),
        },
      ]
    }, [setView, t, view])

    const dropdownMenuProps: Omit<DropdownMenuProps, 'children'> =
      React.useMemo(() => {
        return {
          hideCaret: true,
          items: menuItems,
        }
      }, [menuItems])

    const buttonText = t(Slugs[view])

    return (
      <div
        className={styles.cleansTimeline}
        data-testid={CleansTimelineTestIds.container}
      >
        <div
          className={styles.monthHeader}
          data-testid={CleansTimelineTestIds.select}
        >
          <div>
            <St.ViewPickerContainer>
              View
              <DropdownButton
                dropdownMenuProps={dropdownMenuProps}
                buttonType="text"
              >
                <>
                  {buttonText}
                  <CustomCaret />
                </>
              </DropdownButton>
            </St.ViewPickerContainer>
          </div>
          <div>
            {view === 'day' && (
              <St.Calendar
                icon={IconName.calendar}
                size={30}
                onClick={showDatePickerModal}
              />
            )}
          </div>
          <CreateVisitDrawer />
        </div>

        <>
          <div className={styles.lastFetchSpacer} />
          <LastFetchBanner
            className={styles.lastFetchHeader}
            lastFetch={lastFetchTimestamp}
          />
        </>

        {activeVisits.length > 0 && (
          <>
            <St.ActiveVisitsHeader
              onClick={() => setShowActiveVisits(!showActiveVisits)}
              data-testid={CleansTimelineTestIds.activeVisitsHeader}
            >
              <St.ActiveVisitsTitle>
                {t(Slugs.inProgress)} ({activeVisits.length})
              </St.ActiveVisitsTitle>
              <St.CollapseIcon
                icon={IconName.chevronDown}
                size={24}
                className={classNames({ collapsed: !showActiveVisits })}
              />
            </St.ActiveVisitsHeader>

            {showActiveVisits && (
              <St.ActiveVisitsContainer
                data-testid={CleansTimelineTestIds.activeVisitsContainer}
              >
                <TimelineDay
                  cleans={[]}
                  isStartOfSection={true}
                  isToday={true}
                  onCleanClick={onCleanClick}
                  visits={activeVisits}
                />
              </St.ActiveVisitsContainer>
            )}
          </>
        )}

        {dedupedDayEntries.map(({ dayKey, dayBucket }, index) => {
          const fromFreezeWindowEnd = daysFromFreezeWindow(dayKey)
          const isFrozenEnd = fromFreezeWindowEnd === 1
          const isUnfrozenStart = fromFreezeWindowEnd === 0
          const isToday = isSameDay(startOfDay(dayKey), TODAY)
          const isNextDay = isSameDay(startOfDay(dayKey), addDays(TODAY, 1))

          // Is this the first day of a month - only used for visual marker
          const isFirstDayOfMonth =
            index === 0 ||
            !dayKey.startsWith(dedupedDayEntries[index - 1].dayKey.slice(0, 7))

          // For consistency, we're not applying special spacing styling
          // This ensures consistent padding between all days
          const shouldApplySpecialStyling = false

          // Keep visual indication of today but with consistent spacing
          const applyTodayColor = isToday
          const applyTodaySpacing = false

          /**
           * Hide the date if:
           * 1) The day is before today and
           * 2) The day does not contain an uncompleted clean
           * 3) The view is not one day
           */
          const shouldHideDate =
            isBefore(startOfDay(dayKey), TODAY) &&
            !dayBucket.find(clean => !clean.completedAt) &&
            view !== 'day'

          if (shouldHideDate) {
            return null
          }

          return (
            <div
              className={classNames(styles.dayBucketWrapper, {
                [styles.freezeWindowEnd]: isFrozenEnd,
              })}
              id={isToday ? 'today' : `${dayKey}`}
              key={dayKey}
            >
              {isUnfrozenStart && (
                <div className={styles.freezeWindowBanner}>
                  {t(Slugs.freezeWindowBody)}
                </div>
              )}

              <div className={styles.timelineNav}>
                <div
                  className={classNames({
                    [styles.todayDayMarker]: applyTodayColor,
                    [styles.notTodayDayMarker]: !applyTodayColor,
                    [styles.startOfMonthBucket]:
                      shouldApplySpecialStyling &&
                      (isUnfrozenStart || isFirstDayOfMonth),
                    [styles.today]: applyTodaySpacing,
                  })}
                  style={{
                    position: 'absolute',
                    right: applyTodayColor ? '-6px' : '-5px',
                    top: '32px', // Use consistent default spacing for all markers
                  }}
                />

                <div
                  className={classNames(styles.dayOfWeek, {
                    [styles.today]: applyTodaySpacing,
                    [styles.startOfMonthBucket]:
                      shouldApplySpecialStyling &&
                      (isUnfrozenStart || isFirstDayOfMonth),
                  })}
                  style={{
                    color: applyTodayColor ? colors.lake : undefined,
                    paddingTop: '32px', // Consistent padding for all day names
                  }}
                >
                  {formatLocalized(dayKey, DateFormat.DayNameShort)}
                </div>
                <div
                  className={classNames(styles.dayOfMonth, {
                    [styles.today]: applyTodaySpacing,
                  })}
                  style={applyTodayColor ? { color: colors.lake } : undefined}
                >
                  {formatLocalized(dayKey, DateFormat.DayOfMonth)}
                </div>
              </div>

              <TimelineDay
                cleans={dayBucket}
                isStartOfSection={
                  shouldApplySpecialStyling &&
                  (isUnfrozenStart || isFirstDayOfMonth)
                }
                isToday={false}
                onCleanClick={onCleanClick}
                visits={
                  isToday
                    ? visitsOnToday
                    : isNextDay
                    ? visitsOnNextDay
                    : EMPTY_ARR
                }
              />
            </div>
          )
        })}
      </div>
    )
  },
)
