import { createAsyncAction } from 'typesafe-actions'

import { RequireAllExceptOptional } from 'packages/grimoire/src/utils'
import {
  apiDateStringWithSeconds,
  createDateObject,
} from 'packages/utils/dateHelpers'
import {
  OfflineRequestConfig,
  RequestOptions,
  setRequestRelationship,
} from 'packages/utils/store'

import {
  NormalizedCleanTimesApiResponse,
  CleanTimePostData,
  CleanTimePostApiAttributes,
  CleanTimesActionTypes,
} from 'app/fieldapp/store/cleantimes'

import { updateClean } from '../../cleans/actions'
import { getCleansByAssignmentIds } from '../../cleans/selectors'
import { ApplicationState } from '../../store'
import { offlineTimers } from '../../utils'
import { cleanTimesService } from '../cleantimes.service'
import { getCleanUpdate } from './createFinalizedCleanTime.helpers'
import { fetchActiveCleanTimesForUser } from './fetchActiveCleanTimesForUser'

export const createFinalizedCleanTimeAction = createAsyncAction(
  CleanTimesActionTypes.CREATE_FINALIZED_CLEAN_TIME,
  CleanTimesActionTypes.CREATE_FINALIZED_CLEAN_TIME_SUCCESS,
  CleanTimesActionTypes.CREATE_FINALIZED_CLEAN_TIME_FAILURE,
)<
  OfflineRequestConfig<NormalizedCleanTimesApiResponse, CleanTimePostData>,
  { localId: string; result: NormalizedCleanTimesApiResponse },
  Error
>()

export type FinalizedCleanTimePostData = Omit<
  RequireAllExceptOptional<CleanTimePostData, 'notes'>,
  'isFinalized'
>

export const buildRequestData = (postData): RequestOptions => {
  const { assignmentId, startedAt, stoppedAt, notes } = postData
  const attributes: CleanTimePostApiAttributes = {
    is_finalized: true,
    notes,
    started_at: apiDateStringWithSeconds(startedAt),
    stopped_at: apiDateStringWithSeconds(stoppedAt),
  }

  return {
    data: {
      attributes,
      relationships: {
        ...setRequestRelationship('assignment', assignmentId),
      },
      type: 'clean_time',
    },
  }
}

/**
 * Posts a finalized timer. Used for working with timers started offline.
 * The localId is not posted to the API, but is used to delete any offline data.
 */
export const createFinalizedCleanTime =
  (localId: string, postData: CleanTimePostData) =>
  async (dispatch, getState: () => ApplicationState) => {
    const state = getState()
    const existingClean = getCleansByAssignmentIds(state, [
      postData.assignmentId,
    ])[postData.assignmentId]

    const cleanUpdate = getCleanUpdate(
      existingClean,
      createDateObject(postData.startedAt),
    )

    try {
      const requestData = buildRequestData(postData)
      const request = cleanTimesService.createCleanTime.bind(null, requestData)
      const result = await dispatch(
        createFinalizedCleanTimeAction.request({ data: postData, request }),
      )

      await offlineTimers.removeCleanTime(localId)

      dispatch(createFinalizedCleanTimeAction.success({ localId, result }))

      await dispatch(fetchActiveCleanTimesForUser())

      if (cleanUpdate) await dispatch(updateClean(cleanUpdate))
    } catch (error) {
      dispatch(createFinalizedCleanTimeAction.failure(error))
      throw error
    }
  }
