import { BannerConfig, SuperModalContextConfig } from 'packages/common'
import { TranslatorFn } from 'packages/i18n'
import { createDateString, createDateObject } from 'packages/utils/dateHelpers'

import { Slugs } from 'app/fieldapp/i18n'

export const createDefaultBannerConfig = (t: TranslatorFn): BannerConfig => ({
  link: {
    text: t(Slugs.checkHoursTracker),
    url: 'https://admin.vacasa.com/admin/tools/hours-tracker',
  },
  message: t(Slugs.genericHoursTrackerMsg),
})

export const createConfirmTimerDeletionModal =
  (t: TranslatorFn) =>
  (confirmSubmission: () => void): SuperModalContextConfig => {
    return {
      buttons: [
        {
          onClick: close => {
            close()
          },
          text: t(Slugs.rejectDelete),
          type: 'utility',
        },
        {
          onClick: async (close, { setButtonsLoading }) => {
            setButtonsLoading(true)
            await confirmSubmission()
            close()
          },
          text: t(Slugs.confirmDelete),
          type: 'primary',
        },
      ],
      message: t(Slugs.deleteSubmissionTextBlock),
      title: t(Slugs.deleteSubmissionTitle),
    }
  }

export const NEW_TIMER_ID_PLACEHOLDER = 'NEW'

type TimerTimestamps = {
  startedAt: string
  stoppedAt: string
}

export const convertTimestamps = (
  started: string,
  stopped: string,
): TimerTimestamps => {
  const startedAt = createDateObject(started)
  const stoppedAt = createDateObject(stopped)

  startedAt.setSeconds(1)
  stoppedAt.setSeconds(0)

  return {
    startedAt: createDateString(startedAt),
    stoppedAt: createDateString(stoppedAt),
  }
}
