enum Slugs {
  access = 'access',
  accuracy = 'accuracy',
  add = 'add',
  addAssignee = 'addAssignee',
  addChecklistItem = 'addChecklistItem',
  addContractorAgencyBody = 'addContractorAgencyBody',
  addContractorAgencyTitle = 'addContractorAgencyTitle',
  addCustomChecklistItemError = 'addCustomChecklistItemError',
  addCustomChecklistItemSuccess = 'addCustomChecklistItemSuccess',
  addExcluded = 'addExcluded',
  addExcludedDescription = 'addExcludedDescription',
  addFilter = 'addFilter',
  addFilters = 'addFilters',
  addHousekeeper = 'addHousekeeper',
  addManager = 'addManager',
  addMandatory = 'addMandatory',
  addMandatoryDescription = 'addMandatoryDescription',
  addRemoveDays = 'addRemoveDays',
  addSuggested = 'addSuggested',
  addSuggestedDescription = 'addSuggestedDescription',
  addUnit = 'addUnit',
  addVisit = 'addVisit',
  addVisitInspectionSubtitle = 'addVisitInspectionSubtitle',
  addVisitSuccessMessage = 'addVisitSuccessMessage',
  additionalHomeInfo = 'additionalHomeInfo',
  address = 'address',
  administrative = 'administrative',
  alarmCode = 'alarmCode',
  all = 'all',
  alreadyBorrowed = 'alreadyBorrowed',
  alreadyInZone = 'alreadyInZone',
  anErrorOccurred = 'anErrorOccurred',
  anErrorOccurredLong = 'anErrorOccurredLong',
  and = 'and',
  applicationErrorBody = 'applicationErrorBody',
  applicationErrorTitle = 'applicationErrorTitle',
  apply = 'apply',
  areYouSure = 'areYouSure',
  arrival = 'arrival',
  arrivalAndDeparture = 'arrivalAndDeparture',
  asOfRightNow = 'asOfRightNow',
  assigned = 'assigned',
  assignedTo = 'assignedTo',
  assignee = 'assignee',
  assignment = 'assignment',
  assignmentChangeError = 'assignmentChangeError',
  authErrorBody = 'authErrorBody',
  awaiting = 'awaiting',
  b2Bs = 'b2Bs',
  backToDashboard = 'backToDashboard',
  backup = 'backup',
  badgeB2b = 'badgeB2b',
  badgeEarly = 'badgeEarly',
  badgeLate = 'badgeLate',
  borrow = 'borrow',
  borrowHousekeeperModalConfirm = 'borrowHousekeeperModalConfirm',
  borrowHousekeeperModalTitle = 'borrowHousekeeperModalTitle',
  borrowed = 'borrowed',
  borrowedHousekeeper = 'borrowedHousekeeper',
  borrowedHousekeepersWillNotHaveCleansAssigned = 'borrowedHousekeepersWillNotHaveCleansAssigned',
  brandGreetingStart = 'brandGreetingStart',
  canNowAssignCleansForUnit = 'canNowAssignCleansForUnit',
  canNowAssignCleansToHk = 'canNowAssignCleansToHk',
  cancel = 'cancel',
  cancelled = 'cancelled',
  cannotAddExtraDayWarning = 'cannotAddExtraDayWarning',
  cannotLoadDate = 'cannotLoadDate',
  cannotRemoveHkFromActiveClean = 'cannotRemoveHkFromActiveClean',
  change = 'change',
  checkOut = 'checkOut',
  checkin = 'checkin',
  checklistInfo = 'checklistInfo',
  checklistItem = 'checklistItem',
  city = 'city',
  clean = 'clean',
  cleanNotesEditNote = 'cleanNotesEditNote',
  cleanNotesFallback1 = 'cleanNotesFallback1',
  cleanNotesFallback2 = 'cleanNotesFallback2',
  cleanNotesPlaceholder = 'cleanNotesPlaceholder',
  cleanNotesSaveNote = 'cleanNotesSaveNote',
  cleanNotesSubtitle = 'cleanNotesSubtitle',
  cleanNotesSuccess = 'cleanNotesSuccess',
  cleanNotesTitle = 'cleanNotesTitle',
  cleanStatusCompleted = 'cleanStatusCompleted',
  cleanStatusInProgress = 'cleanStatusInProgress',
  cleanStatusNotReady = 'cleanStatusNotReady',
  cleanStatusOverdue = 'cleanStatusOverdue',
  cleanStatusOverdueInProgress = 'cleanStatusOverdueInProgress',
  cleanStatusOverdueNotStarted = 'cleanStatusOverdueNotStarted',
  cleanStatusReady = 'cleanStatusReady',
  cleanType = 'cleanType',
  cleanUpdatedToast = 'cleanUpdatedToast',
  cleanliness = 'cleanliness',
  clearFilters = 'clearFilters',
  comfort = 'comfort',
  comments = 'comments',
  communication = 'communication',
  completeCleanByManagerModalText = 'completeCleanByManagerModalText',
  completeCleanByManagerModalTitle = 'completeCleanByManagerModalTitle',
  completeContractorCleanModalAlert = 'completeContractorCleanModalAlert',
  completeContractorCleanModalText = 'completeContractorCleanModalText',
  completed = 'completed',
  completionRate = 'completionRate',
  confirm = 'confirm',
  confirmCustomInspectionItemDelete = 'confirmCustomInspectionItemDelete',
  confirmed = 'confirmed',
  contractor = 'contractor',
  contractorAgency = 'contractorAgency',
  contractorInvoicing = 'contractorInvoicing',
  copied = 'copied',
  copy = 'copy',
  copyFailed = 'copyFailed',
  coveragePartnerErrorMessage = 'coveragePartnerErrorMessage',
  coveragePartnerExplanation = 'coveragePartnerExplanation',
  coveragePartnerLabel = 'coveragePartnerLabel',
  coveragePartnerPlaceholder = 'coveragePartnerPlaceholder',
  coveragePartnerSearchNoResults = 'coveragePartnerSearchNoResults',
  coveragePartnerSearchTypeToSearch = 'coveragePartnerSearchTypeToSearch',
  coveragePartnerSuccessMessage = 'coveragePartnerSuccessMessage',
  coveragePartnerTitle = 'coveragePartnerTitle',
  createTicket = 'createTicket',
  createVisitErrorOtherUserVisit = 'createVisitErrorOtherUserVisit',
  createVisitErrorOtherUserVisitThisDay = 'createVisitErrorOtherUserVisitThisDay',
  createVisitErrorUnitHasReservation = 'createVisitErrorUnitHasReservation',
  createVisitErrorUnitHasUnassignedTodayVisit = 'createVisitErrorUnitHasUnassignedTodayVisit',
  createVisitErrorUnitHasUnassignedVisitThisDay = 'createVisitErrorUnitHasUnassignedVisitThisDay',
  createVisitErrorUserHasVisit = 'createVisitErrorUserHasVisit',
  createVisitErrorUserHasVisitThisDay = 'createVisitErrorUserHasVisitThisDay',
  createVisitExistingVisitError = 'createVisitExistingVisitError',
  createVisitGenericError = 'createVisitGenericError',
  createVisitReservationFetchingError = 'createVisitReservationFetchingError',
  createVisitUserStandardAvailabilityFetchingError = 'createVisitUserStandardAvailabilityFetchingError',
  created = 'created',
  custom = 'custom',
  customChecklists = 'customChecklists',
  date = 'date',
  day = 'day',
  dayOff = 'dayOff',
  dayView = 'dayView',
  daysAgo = 'daysAgo',
  daysOccupied = 'daysOccupied',
  deep = 'deep',
  deepCleanDue = 'deepCleanDue',
  deepCleanSkipFootnote = 'deepCleanSkipFootnote',
  deepCleanSkipSuccess = 'deepCleanSkipSuccess',
  deepCleans = 'deepCleans',
  deleteCustomChecklistItemError = 'deleteCustomChecklistItemError',
  deleteCustomChecklistItemSuccess = 'deleteCustomChecklistItemSuccess',
  departure = 'departure',
  description = 'description',
  dismiss = 'dismiss',
  done = 'done',
  due = 'due',
  dueBy = 'dueBy',
  dueDate = 'dueDate',
  edit = 'edit',
  editAssignment = 'editAssignment',
  editB2bWarning = 'editB2bWarning',
  editClean = 'editClean',
  editInfo = 'editInfo',
  editInstructions = 'editInstructions',
  editType = 'editType',
  editZone = 'editZone',
  employee = 'employee',
  error = 'error',
  errorGeneric = 'errorGeneric',
  errorHKRemovalWithUnit = 'errorHKRemovalWithUnit',
  errorHkBorrowedRemovalWithUnit = 'errorHkBorrowedRemovalWithUnit',
  errorTechSupport = 'errorTechSupport',
  excluded = 'excluded',
  excludedUpdated = 'excludedUpdated',
  extraCleanDays = 'extraCleanDays',
  facilities = 'facilities',
  gateCode = 'gateCode',
  guestArrival = 'guestArrival',
  guestStay = 'guestStay',
  headerCrossCoverage = 'headerCrossCoverage',
  headerName = 'headerName',
  headerRoles = 'headerRoles',
  headerTickets = 'headerTickets',
  headerUnits = 'headerUnits',
  highlights = 'highlights',
  hkInstructions = 'hkInstructions',
  homeVisits = 'homeVisits',
  hour = 'hour',
  housekeeper = 'housekeeper',
  housekeeperNotAvailable = 'housekeeperNotAvailable',
  housekeepersUpdated = 'housekeepersUpdated',
  housekeeping = 'housekeeping',
  housekeepingContractors = 'housekeepingContractors',
  housekeepingHub = 'housekeepingHub',
  hrs = 'hrs',
  inProgress = 'inProgress',
  includesCoverage = 'includesCoverage',
  inspection = 'inspection',
  inspectionComplete = 'inspectionComplete',
  inspectionNotComplete = 'inspectionNotComplete',
  inspectionNotes = 'inspectionNotes',
  invalidImage = 'invalidImage',
  invalidTeamClean = 'invalidTeamClean',
  invalidThumbnail = 'invalidThumbnail',
  isDueToday = 'isDueToday',
  isUrgent = 'isUrgent',
  items = 'items',
  keepEditing = 'keepEditing',
  lastCheckOut = 'lastCheckOut',
  lastCompleted = 'lastCompleted',
  letUsKnowCreateATicket = 'letUsKnowCreateATicket',
  loading = 'loading',
  location = 'location',
  lockbox = 'lockbox',
  logout = 'logout',
  maintenance = 'maintenance',
  managedBy = 'managedBy',
  manager = 'manager',
  mandatory = 'mandatory',
  mandatoryHkNote = 'mandatoryHkNote',
  mandatoryUpdated = 'mandatoryUpdated',
  markCleanComplete = 'markCleanComplete',
  markCleanCompleteError = 'markCleanCompleteError',
  markCleanCompleteSuccess = 'markCleanCompleteSuccess',
  menu = 'menu',
  midStay = 'midStay',
  missed = 'missed',
  missedClean = 'missedClean',
  moreDetailsOnHome = 'moreDetailsOnHome',
  myHousekeepers = 'myHousekeepers',
  myUnits = 'myUnits',
  name = 'name',
  network = 'network',
  new = 'new',
  newChecklistItem = 'newChecklistItem',
  newGuestReview = 'newGuestReview',
  newHousekeepersWillNotHaveCleansAssignedInNextThreeDays = 'newHousekeepersWillNotHaveCleansAssignedInNextThreeDays',
  nextCheckIn = 'nextCheckIn',
  nextCheckOut = 'nextCheckOut',
  noActivity = 'noActivity',
  noAssigneeDesignated = 'noAssigneeDesignated',
  noAssignmentHistory = 'noAssignmentHistory',
  noComments = 'noComments',
  noHighlights = 'noHighlights',
  noInspectionNotes = 'noInspectionNotes',
  noOptions = 'noOptions',
  noPhoto = 'noPhoto',
  noResults = 'noResults',
  noResultsFound = 'noResultsFound',
  noTicketsAlert = 'noTicketsAlert',
  notYet = 'notYet',
  note = 'note',
  notes = 'notes',
  occupancy = 'occupancy',
  occupied = 'occupied',
  off = 'off',
  on = 'on',
  onlineListing = 'onlineListing',
  onlyAbleToAddActiveUnitsWithReservations = 'onlyAbleToAddActiveUnitsWithReservations',
  oopsChangesNotSaved = 'oopsChangesNotSaved',
  other = 'other',
  overdueSince = 'overdueSince',
  overdueToolTipTextWithNumber = 'overdueToolTipTextWithNumber',
  overdueTooltipText = 'overdueTooltipText',
  owner = 'owner',
  ownerArrival = 'ownerArrival',
  ownerCheckoutCannotEditClean = 'ownerCheckoutCannotEditClean',
  ownerCheckoutExtraInfo = 'ownerCheckoutExtraInfo',
  ownerHold = 'ownerHold',
  pageNotFound = 'pageNotFound',
  password = 'password',
  payContractorText = 'payContractorText',
  pending = 'pending',
  planVisit = 'planVisit',
  postStay = 'postStay',
  privateReview = 'privateReview',
  propertyCondition = 'propertyCondition',
  publicReview = 'publicReview',
  ready = 'ready',
  reason = 'reason',
  reasonForSkip = 'reasonForSkip',
  rejected = 'rejected',
  remove = 'remove',
  removeExcluded = 'removeExcluded',
  removeExcludedDescription = 'removeExcludedDescription',
  removeHousekeeperNote = 'removeHousekeeperNote',
  removeHousekeeperTitle = 'removeHousekeeperTitle',
  removeMandatory = 'removeMandatory',
  removeMandatoryDescription = 'removeMandatoryDescription',
  removeSuggested = 'removeSuggested',
  removeSuggestedDescription = 'removeSuggestedDescription',
  removeUnitNote = 'removeUnitNote',
  removeUnitTitle = 'removeUnitTitle',
  removed = 'removed',
  requestedBy = 'requestedBy',
  reservation = 'reservation',
  reservationId = 'reservationId',
  resgrid = 'resgrid',
  result = 'result',
  returnToHomePage = 'returnToHomePage',
  review = 'review',
  reviewDate = 'reviewDate',
  reviewOverallRating = 'reviewOverallRating',
  reviewReply = 'reviewReply',
  routine = 'routine',
  save = 'save',
  saveAssigneeError = 'saveAssigneeError',
  schedule = 'schedule',
  scheduleMenuTitleSmStaff = 'scheduleMenuTitleSmStaff',
  scheduleMenuTitleSmUnit = 'scheduleMenuTitleSmUnit',
  scheduledOn = 'scheduledOn',
  searchAllHousekeepers = 'searchAllHousekeepers',
  searchByUnitId = 'searchByUnitId',
  searchHousekeepersName = 'searchHousekeepersName',
  searchUnitNameOrCode = 'searchUnitNameOrCode',
  searchUnits = 'searchUnits',
  seeAllTicketsForUnit = 'seeAllTicketsForUnit',
  seeAnIssue = 'seeAnIssue',
  select = 'select',
  selectHousekeeper = 'selectHousekeeper',
  selectZone = 'selectZone',
  service = 'service',
  severity = 'severity',
  severityLow = 'severityLow',
  severityMedium = 'severityMedium',
  severityPlanned = 'severityPlanned',
  severityPriorToNextGuest = 'severityPriorToNextGuest',
  severityToday = 'severityToday',
  severityUrgent = 'severityUrgent',
  sinceInitialClean = 'sinceInitialClean',
  sinceLastDeepClean = 'sinceLastDeepClean',
  skip = 'skip',
  sorryPageVacation = 'sorryPageVacation',
  staff = 'staff',
  start = 'start',
  started = 'started',
  state = 'state',
  stayover = 'stayover',
  staysOver30Days = 'staysOver30Days',
  suggested = 'suggested',
  suggestedUpdated = 'suggestedUpdated',
  ticket = 'ticket',
  ticketRouting = 'ticketRouting',
  ticketRoutingConfirmModalBody = 'ticketRoutingConfirmModalBody',
  ticketRoutingConfirmModalConfirmText = 'ticketRoutingConfirmModalConfirmText',
  ticketRoutingConfirmModalTitle = 'ticketRoutingConfirmModalTitle',
  ticketRoutingConfirmModalXcLinkText = 'ticketRoutingConfirmModalXcLinkText',
  ticketRoutingCredentialsInfo = 'ticketRoutingCredentialsInfo',
  ticketRoutingLead = 'ticketRoutingLead',
  ticketUpdateFailure = 'ticketUpdateFailure',
  ticketUpdateSuccess = 'ticketUpdateSuccess',
  tickets = 'tickets',
  ticketsAddedToVisit = 'ticketsAddedToVisit',
  tier = 'tier',
  today = 'today',
  tomorrow = 'tomorrow',
  tooBusy = 'tooBusy',
  toolTipB2b = 'toolTipB2b',
  toolTipEarly = 'toolTipEarly',
  toolTipLate = 'toolTipLate',
  toolTipPotentialB2b = 'toolTipPotentialB2b',
  turnover = 'turnover',
  typeToSearch = 'typeToSearch',
  unassigned = 'unassigned',
  unit = 'unit',
  unitId = 'unitId',
  unitInfo = 'unitInfo',
  unitIsOccupied = 'unitIsOccupied',
  unitIsVacant = 'unitIsVacant',
  unitLastVisited = 'unitLastVisited',
  unitPreferences = 'unitPreferences',
  unitSearch = 'unitSearch',
  unitSearchNoResults = 'unitSearchNoResults',
  unitStatus = 'unitStatus',
  unitTickets = 'unitTickets',
  unitWord = 'unitWord',
  units = 'units',
  unknown = 'unknown',
  upNext = 'upNext',
  update = 'update',
  updateCustomChecklistItemError = 'updateCustomChecklistItemError',
  updateCustomChecklistItemSuccess = 'updateCustomChecklistItemSuccess',
  vacant = 'vacant',
  vacantToolTip = 'vacantToolTip',
  vacasaHold = 'vacasaHold',
  value = 'value',
  viewHome = 'viewHome',
  viewListing = 'viewListing',
  viewOpenTickets = 'viewOpenTickets',
  viewReservation = 'viewReservation',
  viewTicket = 'viewTicket',
  viewTickets = 'viewTickets',
  visit = 'visit',
  visitScheduled = 'visitScheduled',
  visitUpdated = 'visitUpdated',
  visits = 'visits',
  week = 'week',
  weekView = 'weekView',
  wifi = 'wifi',
  yesDelete = 'yesDelete',
  youAreOverdue = 'youAreOverdue',
  youHaveTimeLeft = 'youHaveTimeLeft',
  youHaveTimeUntil = 'youHaveTimeUntil',
  zone = 'zone',
  zoneCompletion = 'zoneCompletion',
  zoneIsNotActive = 'zoneIsNotActive',
  zoneName = 'zoneName',
  zoneNameAlreadyExists = 'zoneNameAlreadyExists',
  zoneUpdated = 'zoneUpdated',
}

export default Slugs
