import { AxiosResponse } from 'axios'
import { map, pipe, reject, snakeCase } from 'lodash/fp'

import { OfflinePatchData } from 'packages/offline'
import { flippedIncludes } from 'packages/utils/misc/array.helpers'

import { Assignment, JSONApiAssignmentsMap } from '../assignment'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from '../utils'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type CleanTimesResponse = {
  assignment: JSONApiAssignmentsMap
  cleanTime: JSONApiCleanTimesMap
}

export type CleanTimePostData = {
  assignmentId: string
  isFinalized?: boolean
  notes?: string
  startedAt: string
  stoppedAt?: string
}

export type CleanTimePostApiAttributes = {
  is_finalized?: boolean
  notes?: string
  started_at: string
  stopped_at?: string
}

export type CleanTimePatchData = {
  id: string
} & CleanTimePatchAttributes

type StaticCleanTimePatchData = {
  isFinalized?: boolean
  isVisitInpsectionTimer?: boolean
  notes?: string
  startedOffline?: boolean
}

export type CleanTimePatchAttributes = {
  startedAt?: string
  stoppedAt?: string | null
} & StaticCleanTimePatchData

export type CleanTimeOfflinePatchData = {
  startedAt?: string | null
  stoppedAt?: string | null
} & StaticCleanTimePatchData

export type CleanTimePatchApiAttributes = {
  is_finalized?: boolean
  notes?: string
  started_at?: string
  stopped_at?: string | null
}

export type NormalizedCleanTimesApiResponse =
  NormalizedJSONApiResponse<CleanTimesResponse>
export type CleanTimesServiceResponse = Promise<NormalizedCleanTimesApiResponse>
export type CleanTimesApiResponse =
  AxiosResponse<NormalizedCleanTimesApiResponse>

/**********************************************************
 * CLEAN TIME
 *********************************************************/
export type CleanTime = {
  assignment: Assignment
  id: string
} & CleanTimeAttributes

export type CleanTimeAttributes = {
  isFinalized: boolean
  notes?: string
  startedAt: string
  startedOffline?: boolean
  stoppedAt: string | null
}

export const CleanTimeAttributeNames = [
  'isFinalized',
  'notes',
  'startedAt',
  'stoppedAt',
  'startedOffline',
]

/**
Attributes that we don't or can't use in requests that we remove below:
- API does not accept "notes" as a field request, but we do still need it 
for offline timers storage
- StartedOffline is an attribute that only exists locally
*/
const localAttributes = ['notes', 'startedOffline']

// API does not accept "notes" as a field request, but we do still need it
// for offline timers storage, so we need to manually remove it here
export const CleanTimeApiFields = pipe(
  reject(flippedIncludes<string>(localAttributes)),
  map(snakeCase),
)(CleanTimeAttributeNames)

export type CleanTimeRelationships = {
  assignment: ToOneRelationship
}

export type OfflineCleanTime = OfflinePatchData<CleanTimeOfflinePatchData>
export type RawCleanTime = JSONApiObjectWithRelationships<
  CleanTimeAttributes,
  CleanTimeRelationships
>

export type JSONApiCleanTimesMap = JSONApiObjectWithRelationshipsMap<
  CleanTimeAttributes,
  CleanTimeRelationships
>
