export enum Features {
  CREATING_VISITS_FROM_HK_HUB = 'creating_visits_from_hk_hub',
  CROSS_COVERAGE = 'cross_coverage',
  CUSTOM_INSPECTION_CHECKLIST = 'custom_inspection_checklist',
  DYNAMIC_ZONE_ID_HANDLING = 'enable_dynamic_zone_id_handling_for_websockets',
  FETCH_ALL_ACTIVE_VISITS = 'fetch_all_active_visits',
  FORCED_OWNER_COMMENT = 'forced-owner-comment',
  IS_TICKET_PROCESSED_FLAG = 'is_ticket_processed_flag',
  MAINTENANCE = 'fieldops-web-maintenance-mode',
  MAKING_BORROWED_HK_MANDATORY_FRONTEND = 'making_borrowed_hk_mandatory_frontend',
  NEW_TICKET_ATTACHMENTS_ENDPOINT = 'new_ticket_attachments_endpoint',
  ONCALL_USERS_FETCHING = 'oncall-users-fetching',
  TICKET_ATTACHMENTS = 'ticket_attachments',
  TIMER_PURGE_DEBOUNCE = 'timer_purge_debounce',
}
