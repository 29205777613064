import { useDispatch, useSelector } from 'react-redux'

import { useToast } from 'packages/common'
import { OtherTimer, OtherTimersPostData } from 'packages/grimoire'
import {
  PartialAllExceptRequired,
  ReduxDispatch,
} from 'packages/grimoire/src/utils'
import {
  GenericTimer,
  TimerType,
} from 'packages/grimoire/src/utils/timers.types'
import { createDateString } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks'

import { useTimers as useTimersContext } from 'app/fieldapp/components/timers/state'
import {
  clearLoadingTimer,
  clearTimerSubmission,
  setLoadingTimer,
  setSubmissionData,
} from 'app/fieldapp/components/timers/state/timers.actions'
import { Timer } from 'app/fieldapp/components/timers/state/timers.types'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import {
  createOtherTimer,
  deleteOtherTimer,
  updateOtherTimer,
  updateOtherTimerOffline,
} from 'app/fieldapp/store/otherTimers/actions'
import { createFinalizedOtherTimer } from 'app/fieldapp/store/otherTimers/actions/createFinalizedOtherTimer'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'

import { useTimerErrors } from '../../useTimerErrors'
import {
  NEW_TIMER_ID_PLACEHOLDER,
  convertTimestamps,
} from '../useTimerActions.helpers'

type TimerWithNotes = Timer & {
  notes: string
}

export type UseOtherTimerActions = {
  dispatchBeginOtherTimerStartedOfflineSubmission: (
    otherTimer: GenericTimer,
  ) => Promise<void>
  dispatchBeginOtherTimerSubmission: (otherTimer: GenericTimer) => Promise<void>
  dispatchCancelStopOfflineOtherTimer: (id: string) => Promise<void>
  dispatchCancelStopOtherTimer: (id: string) => Promise<void>
  dispatchCompleteOtherTimer: (timer: TimerWithNotes) => Promise<void>
  dispatchCompleteStartedOfflineOtherTimer: (
    timer: PartialAllExceptRequired<OtherTimer, 'id'>,
  ) => Promise<void>
  dispatchCreateOtherTime: ({
    subTimerType,
    timerType,
    userId,
  }: Pick<
    OtherTimersPostData,
    'subTimerType' | 'timerType' | 'userId'
  >) => Promise<void>
  dispatchDeleteOtherTime: (id: string) => Promise<void>
}

export function useOtherTimerActions(): UseOtherTimerActions {
  const reduxDispatch = useDispatch<ReduxDispatch<ApplicationState>>()
  const user = useSelector(getActiveUser)
  const { t } = useI18n()

  const isOnline = useOnlineStatus().isOnline()
  const { dispatch: timersDispatch } = useTimersContext()
  const { showToast } = useToast()
  const { clearExistingErrors, handleError: showErrorBanner } = useTimerErrors()

  /**
   * @deprecated Do not use this in any new code. It suppresses errors that end up not getting handled properly.
   */
  const reduxDispatchWithErrorHandling = async action => {
    try {
      await reduxDispatch(action)
    } catch (e) {
      showErrorBanner(e)
    }
  }

  /**************************************************
   * Start Functions
   **************************************************/
  const dispatchCreateOtherTime = async ({
    subTimerType,
    timerType,
    userId,
  }: Omit<OtherTimersPostData, 'startedAt'>) => {
    timersDispatch(setLoadingTimer(NEW_TIMER_ID_PLACEHOLDER))
    clearExistingErrors()

    await reduxDispatchWithErrorHandling(
      createOtherTimer({
        startedAt: createDateString(),
        subTimerType,
        timerType,
        userId,
      }),
    )

    timersDispatch(clearLoadingTimer())
  }

  /**************************************************
   * Stop Functions
   **************************************************/
  const dispatchStopOtherTimer = async (id: string) => {
    timersDispatch(setLoadingTimer(id))

    try {
      await reduxDispatch(
        updateOtherTimer({
          id,
          stoppedAt: createDateString(),
        }),
      )
    } catch (err) {
      throw Error(err)
    } finally {
      timersDispatch(clearLoadingTimer())
    }
  }

  const dispatchCancelStopOfflineOtherTimer = async (id: string) => {
    timersDispatch(setLoadingTimer(id))
    clearExistingErrors()

    try {
      // Even though we are not necessarily offline, we only care about updating offline data,
      // since the timer has unsynced data
      await reduxDispatch(
        updateOtherTimerOffline({
          data: {
            id,
            stoppedAt: null,
          },
        }),
      )
    } catch (err) {
      throw Error(err)
    } finally {
      timersDispatch(clearLoadingTimer())
      timersDispatch(clearTimerSubmission())
    }
  }

  const dispatchCancelStopOtherTimer = async (id: string) => {
    timersDispatch(setLoadingTimer(id))
    clearExistingErrors()

    await reduxDispatchWithErrorHandling(
      updateOtherTimer({
        id,
        stoppedAt: null,
      }),
    )

    timersDispatch(clearLoadingTimer())
    timersDispatch(clearTimerSubmission())
  }

  /**************************************************
   * Submission Functions
   **************************************************/
  const dispatchCompleteOtherTimer = async (timer: TimerWithNotes) => {
    clearExistingErrors()

    try {
      const { id, startedAt, stoppedAt, notes } = timer
      await reduxDispatch(
        updateOtherTimer({
          id,
          isFinalized: true,
          notes,
          ...convertTimestamps(startedAt, stoppedAt),
        }),
      )

      timersDispatch(clearTimerSubmission())
      showToast({
        message: t(isOnline ? Slugs.hoursSubmitted : Slugs.timerSavedOffline),
      })
    } catch (error) {
      showErrorBanner(error)
    }
  }

  /** Will conditionally post a started offline other timer or update it locally, depending on online status*/
  const dispatchCompleteStartedOfflineOtherTimer = async (
    timer: PartialAllExceptRequired<OtherTimer, 'id'>,
  ) => {
    clearExistingErrors()
    try {
      const { startedAt, stoppedAt, notes, timerType, id } = timer
      /*
       * There is no reason there should not be defined, but in the case they are,
       * don't try anything, because we'll end post/patching bad data
       */
      if (user && stoppedAt && timerType && startedAt) {
        /** Attributes for both patch and post requests */
        const sharedPayload = {
          isFinalized: true,
          notes,
          timerType,
          ...convertTimestamps(startedAt, stoppedAt),
        }
        if (isOnline) {
          // Here, we post the whole timer
          await reduxDispatch(
            createFinalizedOtherTimer(id, {
              ...sharedPayload,
              userId: user.id,
            }),
          )
        } else {
          // If we're offline, we save this so it becomes a "paused timer"
          await reduxDispatch(
            updateOtherTimerOffline({
              data: {
                id,
                ...sharedPayload,
              },
            }),
          )
        }
      }

      timersDispatch(clearTimerSubmission())
      showToast({
        message: t(isOnline ? Slugs.hoursSubmitted : Slugs.timerSavedOffline),
      })
    } catch (error) {
      showErrorBanner(error)
    }
  }

  const dispatchBeginOtherTimerStartedOfflineSubmission = async (
    otherTimer: GenericTimer,
  ) => {
    timersDispatch(clearTimerSubmission())

    try {
      if (!otherTimer.stoppedAt) {
        timersDispatch(setLoadingTimer(otherTimer.id))

        try {
          await reduxDispatch(
            updateOtherTimerOffline({
              data: {
                id: otherTimer.id,
                stoppedAt: createDateString(),
              },
            }),
          )
        } catch (err) {
          throw Error(err)
        } finally {
          timersDispatch(clearLoadingTimer())
        }
      }

      timersDispatch(
        setSubmissionData({
          timer: otherTimer,
          type: TimerType.OTHER,
        }),
      )
    } catch (err) {
      showErrorBanner(err)
    }
  }

  const dispatchBeginOtherTimerSubmission = async (
    otherTimer: GenericTimer,
  ) => {
    timersDispatch(clearTimerSubmission())

    try {
      if (!otherTimer.stoppedAt) {
        await dispatchStopOtherTimer(otherTimer.id)
      }

      timersDispatch(
        setSubmissionData({
          timer: otherTimer,
          type: TimerType.OTHER,
        }),
      )
    } catch (err) {
      showErrorBanner(err)
    }
  }

  /**************************************************
   * Delete Functions
   **************************************************/
  const dispatchDeleteOtherTime = async (id: string) => {
    timersDispatch(setLoadingTimer(id))
    clearExistingErrors()
    await reduxDispatchWithErrorHandling(deleteOtherTimer(id))
    timersDispatch(clearTimerSubmission())
    timersDispatch(clearLoadingTimer())
  }

  return {
    dispatchBeginOtherTimerStartedOfflineSubmission,
    dispatchBeginOtherTimerSubmission,
    dispatchCancelStopOfflineOtherTimer,
    dispatchCancelStopOtherTimer,
    dispatchCompleteOtherTimer,
    dispatchCompleteStartedOfflineOtherTimer,
    dispatchCreateOtherTime,
    dispatchDeleteOtherTime,
  }
}
