import { User } from '@sentry/react'
import classNames from 'classnames'
import * as React from 'react'

import { LegacyDrawer, LegacyDrawerPosition } from 'packages/common'
import { useOnlineStatus } from 'packages/utils/hooks'

import { OfflineNotifierContainer } from 'app/fieldapp/components/core'
import { isViewingSharedClean } from 'app/fieldapp/components/schedule/schedule.utils'
import { FIELD_APP_CONTAINER_ID } from 'app/fieldapp/FieldApp.constants'
import { getFanOutBatchRequests } from 'app/fieldapp/store/app/selectors'
import { CleanTime } from 'app/fieldapp/store/cleantimes/cleantimes.types'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { Housekeeper } from 'app/fieldapp/store/housekeepers/housekeepers.types'
import { OtherTimer } from 'app/fieldapp/store/otherTimers/otherTimers.types'

import {
  ActiveCleanTimer,
  OtherTimersContainer,
  PausedTimers,
  SubmitTimers,
} from '../../components'
import { ActiveOtherTimerContainer } from '../../components/ActiveTimers/ActiveOtherTimer/ActiveOtherTimer.container'
import { ActiveTicketTimer } from '../../components/ActiveTimers/ActiveTicketTimer/ActiveTicketTimer'
import { usePurgeUnlinkedTimers, useTimersRenderState } from '../../hooks'
import { useCheckFailedTimers } from '../../hooks/useCheckFailedTimers/useCheckFailedTimers'
import { setOnlineStatus, useTimers as useTimersContext } from '../../state'
import { getTotalPaddingHeight } from './Timers.helpers'

import styles from './Timers.module.scss'

export const TEST_ID_TIMERS = 'timersContainer'

export type TimersProps = {
  getActiveCleanTime: () => CleanTime | undefined
  getActiveOtherTimer: () => OtherTimer | undefined
  getActiveUser: () => User | undefined
  getHkByUserId: (id: string) => Housekeeper | undefined
}

export const Timers: React.FunctionComponent = React.memo(() => {
  const fanOutBatchRequestsState = useAppSelector(getFanOutBatchRequests)
  const { dispatch, state } = useTimersContext()
  const isOnline = useOnlineStatus().isOnline()
  const isSharedClean = isViewingSharedClean()

  usePurgeUnlinkedTimers()
  useCheckFailedTimers(!isSharedClean && isOnline)

  const componentRenderMap = useTimersRenderState()
  const {
    activeCleanTimer,
    activeOtherTimer,
    activeTicketTimer,
    hasSubmitTimers,
    pausedTimers,
    showOtherTimers,
  } = componentRenderMap

  /**
   * Memoized logic to determine the total padding to apply to the page
   */
  const totalPaddingHeight = React.useMemo(
    () => getTotalPaddingHeight(componentRenderMap),
    [componentRenderMap],
  )

  /**
   * Handles setting padding on the page to allow all the content to be visible
   */
  React.useEffect(() => {
    const FAElement = document.getElementById(FIELD_APP_CONTAINER_ID)

    if (FAElement) {
      FAElement.style.paddingBottom = totalPaddingHeight
    }
  }, [totalPaddingHeight])

  /**
   * Handles setting offline status
   */
  React.useEffect(() => {
    const newHasBeenOffline = state.hasBeenOffline || !isOnline
    if (newHasBeenOffline !== state.hasBeenOffline) {
      dispatch(setOnlineStatus(newHasBeenOffline))
    }
  }, [state.hasBeenOffline, isOnline, dispatch])

  return fanOutBatchRequestsState === 'fulfilled' ? (
    <LegacyDrawer
      className={styles.timersDrawer}
      dataTestId={TEST_ID_TIMERS}
      isOpen={true}
      pinned={true}
      position={LegacyDrawerPosition.BOTTOM}
    >
      <div
        className={classNames(styles.timerDrawerWrapper, {
          [styles.withRadius]: componentRenderMap.showOtherTimers,
        })}
      >
        <OfflineNotifierContainer />
        {showOtherTimers && <OtherTimersContainer />}
        <div className={styles.timersDrawerContent}>
          {activeOtherTimer && <ActiveOtherTimerContainer />}
          {activeCleanTimer && <ActiveCleanTimer />}
          {activeTicketTimer && <ActiveTicketTimer />}
          {pausedTimers && <PausedTimers />}
          {hasSubmitTimers && <SubmitTimers />}
        </div>
      </div>
    </LegacyDrawer>
  ) : null
})
